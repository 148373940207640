import React, { Component, useState } from 'react';
import LogoImg from './assets/website/logo.png'
import HomeTopImg from './assets/website/home_top.png'
import HomeCenterImg from './assets/website/home_center.png'
import HomeBottomImg from './assets/website/home_bottom.png'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Typography,
  Image
} from 'antd';
import {
  BankOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

const {
  Header
} = Layout;
const { Paragraph } = Typography;



class App extends Component {
  state = {
    collapsed: false,
    menuLeft: '-300px',
    menuList: [
      {
        id: 0,
        title: '首页'
      },
      {
        id: 1,
        title: '技术支持'
      }
    ]
  };

  onMenu () {
    this.setState({ menuLeft: '0px' })
  }

  getMenuIcon (item) {
    const { title } = item
    switch (title) {
      case '首页':
        return (<BankOutlined style={{ fontSize: '20px', color: '#81ccf3', marginLeft: '20px', marginTop: '-10px' }} />)
      case '技术支持':
        return (<InfoCircleOutlined style={{ fontSize: '20px', color: '#81ccf3', marginLeft: '20px', marginTop: '-10px' }} />)
      default:
        break;
    }
  }

  render() {
    const {
      collapsed,
      menuLeft,
      menuList
    } = this.state
    return (
     <Layout style={{backgroundColor:'white'}}>
            <Image
                width={'100%'}
                preview={false}
                src={HomeTopImg}
            />
              <Image
                width={'100%'}
                preview={false}
                src={HomeCenterImg}
            />
              <Image
                width={'100%'}
                preview={false}
                src={HomeBottomImg}
            />
            <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center',paddingLeft:'100px',paddingRight:'100px'}}>
                <div style={{fontSize:'26px',color:'#333',width:'80%',display:'flex',flexDirection:'column'}}>       
                  <div style={{marginTop:'20px'}}>联系我们</div>
                  <span style={{marginTop:'20px'}}>地址：西安市新城区石家街127号东岸国际8层802</span>
                  <span>电话：18202972023</span>
                  <span>邮箱：bajietravel@gmail.com</span>
               </div>
              <div style={{fontSize:'26px',color:'#333',display:'flex',flexDirection:'column'}}>
                <div  style={{marginTop:'20px'}}>关于我们</div>
                <span style={{marginTop:'20px',width:'800px',fontSize:'24px'}}>
                    陕西海外旅游有限责任公司西安东元路分公司(八界旅行）我们是一家专注于非洲旅游的公司，为旅行者提供独特而难忘的探险体验。无论您是寻找野生动物，观赏壮丽景观探索文化交流还是刺激的冒险，我们都能为您量身定制旅程，我们拥有经验丰富的导游和工作人员，深诣非洲各个目的地的文化和环境，我们的团队将确保您在旅途中的享受安全、舒适的环境。
                </span>
              </div>
            </div>
            <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center',height:'80px',fontSize:'21px',color:'white',backgroundColor:'#314784',marginTop:'20px'}}>
            @2023 陕西海外旅游有限责任公司西安东元路分公司 备案号：陕ICP备2023006971号
            </div>
          
     </Layout>
    );
  }
}
export default App;