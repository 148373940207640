import React, { Component, useState} from 'react';
import {
  Layout,
  Typography,
  FloatButton,
  Input,
  Button,
  message,

} from 'antd';
import {
  BankOutlined,
  MenuOutlined,
  InfoCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarAlt, faCircleUser, faFaceSmile, faHomeUser, faIdCardAlt, faStar, faUser, faUserAlt, faUserCircle, faUserLargeSlash, faUsers, faUsersLine } from '@fortawesome/free-solid-svg-icons' // solid风格
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import MediaQuery from 'react-responsive'

import styles from './TripHome.css'
import logoImg from '../../assets/logo.png'
import telIcon from '../../assets/tel.png'
import bannerImg from '../../assets/banner.png'
import newLogo from '../../assets/newlogo.png'
import bannerWeb from '../../assets/bannerWeb.png'
import bannerM from '../../assets/bannerMobile.png'
import userAvatar from '../../assets/avatar.png'
import sidebarImg from '../../assets/sidebarImg.png'
import csCodeImg from '../../assets/csCode.png'
import homeIcon from '../../assets/home.png'
import spotIcon from '../../assets/spot.png'
import notesIcon from '../../assets/notes.png'
import strategyIcon from '../../assets/strategy.png'
import likeIcon from '../../assets/like.png'
import starIcon from '../../assets/star.png'
import linkIcon from '../../assets/link.png'

import imgA from '../../assets/picture/1679395309129051.jpg'
import imgB from '../../assets/picture/1679457691170762.jpg'
import imgC from '../../assets/picture/1679395781101250.jpg'
import imgD from '../../assets/picture/1679395379142165.jpg'
import imgE from '../../assets/picture/1679395403208946.jpg'
import imgF from '../../assets/picture/1679395584149378.jpg'
import imgG from '../../assets/picture/323420230714112408.jpeg'
import imgH from '../../assets/picture/1679395505153400.jpg'
import imgI from '../../assets/picture/1679395526193216.jpg'
import imgJ from '../../assets/picture/1233434234.jpeg'
import imgK from '../../assets/picture/1679395440130119.jpg'
import imgL from '../../assets/picture/444123124234.jpeg'
import imgM from '../../assets/picture/1679395549997888.jpg'
import imgN from '../../assets/picture/1220230714105241.jpg'
import imgO from '../../assets/picture/11120230714105413.jpg'
import imgP from '../../assets/picture/11120230714105550.jpg'
import imgQ from '../../assets/picture/1679395891137860.jpg'
import imgR from '../../assets/picture/1679395602176220.jpg'
import imgS from '../../assets/picture/1679453857622723.jpg'
import imgT from '../../assets/picture/1679453951147670.jpg'
import imgU from '../../assets/picture/111.jpg'
import imgV from '../../assets/picture/1679454191164082.jpg'
import imgW from '../../assets/picture/1679454271115682.jpg'
import imgX from '../../assets/picture/1679458079234259.jpg'


const {
  Header,
  Content,
  Footer
} = Layout;
const { Paragraph } = Typography;
const { Search, TextArea } = Input;

class App extends Component {
  state = {
    collapsed: false,
    menuList: [
      {
        id: 0,
        title: '首页'
      },
      {
        id: 1,
        title: '技术支持'
      }
    ],
    quillCnt: '', // 富文本内容
    blog: {
      goodReplayList: [ // 精彩回复列表
        {
          id: 'g1',
          avatar: '',
          username: '新加坡-張翔',
          type: '精彩回复',
          content: [
            {
              id: 'c1',
              "type": "text",
              "txt": "上个月正好休年假，约着家人和朋友一起出游，去了土耳其，一次非常浪漫的土耳其旅行，我个人玩下来非常喜欢，在这里，我简单的分享一下亲身经历，如果你能抽出5分钟耐心看完我这篇文章，应该会让你去土耳其旅游，少走很多弯路!"
            },
            {id: 'c2',
              "type": "img",
              "url": imgA
            },
            {
              id: 'c3',
              "type": "text",
              title:'出发前的担心和疑虑：',
              "txt":"想去土耳其的念头已经很久了，一是实在太远，二是一直觉得安全有点问题，迟迟没有下决心，然后在网上不断做攻略，比价找行程，一周过后，把自己颓进去了，暂时放弃。"
            },
            {
              id: 'c103',
              "type": "text",
              "txt": "在一晚小饭局上，聊到旅游，顺口提了打算去土耳其，去土耳其坐热气球，恰好在坐有朋友去过几趟，说到土耳其安全问题，她不建议我们自由行和自驾游，建议跟当地向导包车游玩，然后给我推了一位常驻土耳其的华人地接Selena，事后证明，此举无比英明。"
            },
            {
              id: 'c104',
              "type": "text",
              wechat:true,
              "txt": "联系Selena，沟通了几天，确定了行程后踏上了向往已久的土耳其之旅，我是家庭和朋友一群人出行，还带着父母，从接机开始，Selena非常热情的为我们跑前跑后左右张罗，有时连饭都还没吃上，又得解决我们遇到的问题，至今想到这，还是非常的感动，在这里给Selena点个赞，顺便帮她打个小广告，请不要介意，她可以免费帮你解答很多出行土耳其的疑问，也可以为你量身定制一款，合适的行程。如有需要，请加Selena的微信，微信号："
            },
            {id: 'c4', "type": "img", "url": imgB},
            {
              id: 'c203',
              "type": "text",
              title:'下面开始讲讲经历',
            },
            {
              id: 'c5',
              "type": "text",
              "txt":"土耳其有“亚欧大陆桥”的美称，被誉为“浪漫星月国”，旅游资源非常丰富，各种教堂遗址;有闻名世界的索非亚大教堂和浪漫的卡帕多奇亚;有风光秀丽的湖滨、海滩;有历史遗址;还有多姿多彩的风土人情。"
            },
            {
              id: 'c6',
              "type": "text",
              title:'关于土耳其入境要求：',
            },
            {
              id: 'c7',
              "type": "text",
              txt:'一、需要在官网上申请土耳其电子签证，分分钟出签。我们是找Selena帮忙办理的',
            },
            {
              id: 'c8',
              "type": "text",
              txt:'二、护照有限期6个月以上，30天的停留期',
            },
            {
              id: 'c9',
              "type": "text",
              title:'【 温馨提示 】',
            },
            {
              id: 'c10',
              "type": "text",
              txt:'航空公司规定，凡是外国护照入境土耳其必须出具离开的航班时间和信息，否则航司有可能会拒绝登机',
            },
            {
              id: 'c11',
              "type": "text",
              txt:'货币：土耳其的货币是里拉，可在机场兑换（24小时营业）美元也适用于土耳其，买东西付款时一定看清货币单位、',
            },
            {
              id: 'c12',
              "type": "text",
              txt:'餐食：一般餐食以当地地道餐食为主，或者酒店自助餐，如果因为宗教或者健康问题对餐食有要求需要提前联系，大部分酒店不提供免费饮料',
            },
            {
              id: 'c13',
              "type": "text",
              title:'去土耳其旅游，到底安不安全?',
            },
            {
              id: 'c14',
              "type": "text",
              txt:'土耳其给很多人的印象是：宗教复杂，比较二哈，野蛮......',
            },
            {
              id: 'c15',
              "type": "text",
              txt:'去土耳其旅行，也要看去哪里，国家的安全程度也分为三六九等',
            },
            {
              id: 'c16',
              "type": "text",
              txt:'从这次土耳其旅行的经历客观来讲，土耳其的治安相对安全，只要按照当地向导的建议出行，可以规避很多不必要的麻烦，也不影响整体出游的体验。',
            },
            {
              id: 'c17',
              "type": "text",
              txt:'土耳其的旅游业非常发达，旅游服务设施也非常成熟，所以大家不用过度担心，土耳其目前也是国人最爱的旅行目的地之一。',
            },
            {id: 'c6', "type": "img", "url": imgC},
            {
              id: 'c18',
              "type": "text",
              title:'土耳其天气和气候。',
            },
            {
              id: 'c17',
              "type": "text",
              txt:'没去土耳其之前我觉得那边会很炎热，但是去了我发现还是书读的少，地理没学好',
            },
            {
              id: 'c18',
              "type": "text",
              txt:'土耳其位于地中海和爱琴海沿岸，北部紧挨黑海，虽然属于地中海气候但是伊斯坦布尔和卡帕多奇亚还是比较清爽的~',
            },
            {
              id: 'c19',
              "type": "text",
              txt:'在春秋季的时候，适合在土耳其的各地游玩，每个地区温度都比较适宜，在冬季的时候，则适合在地中海和爱琴海地区游玩，这里冬天不会冷，气候比较温暖，在夏季的时候，则可以在中部或者东南部内陆游玩，这里的天气则不会很热，所以来土耳其游玩，每个季节都是适合的，而且每个季节也有适合游玩的地方，所以不用担心来到土耳其没有来对时间，那只是你没有来对地方。',
            },
            {id: 'c8', "type": "img", "url": imgD},
            {
              id: 'c20',
              "type": "text",
              title:'关于土耳其旅游省钱攻略。',
            },
            {
              id: 'c21',
              "type": "text",
              txt:'出去旅行要想省钱，还是要找当地的旅游公司或者当地向导，这才能做到真正意义上的省钱，我在新加坡当地旅游公司询价，给到的价格都比在土耳其当地找的旅游公司价格高很多，甚至高出一半的价格。',
            },
            {
              id: 'c22',
              "type": "text",
              txt:'新加坡当地给到的参考旅游路线没有什么特色，出游团期也是固定的，还要在她们指定的时间出行，但是我的假期时间不允许。',
            },
            {
              id: 'c23',
              "type": "text",
              txt:'后来才知道，不管哪里报团，其她国家的旅行社最终还是交个土耳其当地旅游公司负责安排，所以要想省钱，还是要避开中间商找土耳其当地公司，来的比较直接。',
            },
            {
              id: 'c24',
              "type": "text",
              txt:'除了省钱，找当地的服务机构可以一对一实时沟通，解答你的问题，方便了很多，路线设计更加灵活，更加有特色，全程还不赶路，好不容易去趟土耳其，我可不想因为选择失误，毁了我的旅行计划。',
            },
            {
              id: 'c25',
              "type": "text",
              txt:'去土耳其旅游，真心建议找一个当地的中国导游陪同，这样会超级省心，并且还能体会更多的旅游乐趣。再给Selena打个广告，去土耳其旅游，请找Selena，她的微信：',
            },
            {
              id: 'c26',
              "type": "wechat"
            },
            {id: 'c10', "type": "img", "url": imgE},
            {
              id: 'c27',
              "type": "text",
              title:'交通工具的选择。',
            },
            {
              id: 'c28',
              "type": "text",
              txt:'去土耳其旅行一般根据人数有不同车型选择：1-3人是5坐轿车，3-7人是商务车，7人以上就是丰田考斯特一类的',
            },
            {
              id: 'c29',
              "type": "text",
              txt:'土耳其环线玩法省时间省钱一般都要安排内陆飞机，像从安塔利亚-卡帕多奇亚这一段中间没有精彩的地方路途又远完全可以安排内陆飞.',
            },
            {id: 'c12', "type": "img", "url": imgF},
            {
              id: 'c30',
              "type": "text",
              title:'土耳其酒店和用餐。',
            },
            {
              id: 'c31',
              "type": "text",
              txt:'去土耳其选择酒店是有学问的，在伊斯坦布尔可以选择欧洲区老城区附近的酒店靠近博斯普鲁斯海峡景色非常美，也靠近景区卡帕多奇亚的酒店要选择格雷梅镇上的洞穴酒店，如果不会选怎酒店请联系Selena。',
            },
            {
              id: 'c32',
              "type": "text",
              txt:'卡帕多奇亚的酒店位置也比较关键，早晨漫天的热气球是非常美丽的风景，如果酒店位置好真的可以拍到浪漫的热气球景色!卡帕多奇亚的也晚也非常美丽，酒店选的好也能欣赏到美丽的卡帕夜景。',
            },
            {
              id: 'c33',
              "type": "text",
              txt:'费特希耶因为位于海边，所以在费特希耶逗留的要选择海景酒店，Selena帮我们预定了一家轻奢民宿，进入房间还有一个暗门退开门就是蔚蓝的地中海真的太喜欢了，因为这家酒店房间数有限，所以还是不太好预定。',
            },
            {
              id: 'c34',
              "type": "text",
              txt:'用餐在酒店使用自助西餐，相当丰盛，土耳其也是美食国度建议大家可以进行土耳其探店，费特希耶有一家海鲜餐厅不错，卡帕多奇亚的瓦罐海鲜也很好吃。',
            },
            {id: 'c14', "type": "img", "url": imgG},
            {
              id: 'c35',
              "type": "text",
              title:'我选择的旅游路线。',
            },
            {
              id: 'c36',
              "type": "text",
              txt:'第一天：到达土耳其首都伊斯坦布尔。',
            },
            {
              id: 'c37',
              "type": "text",
              txt:'第二天：飞往卡帕多奇亚，美丽的格雷梅镇探索。',
            },
            {
              id: 'c38',
              "type": "text",
              txt:'第三天： 体验浪漫热气球，参加ATV和日落老爷车。',
            },
            {
              id: 'c39',
              "type": "text",
              txt:'第四天：红绿线一日游，感受卡帕的人文和地理。',
            },
            {
              id: 'c40',
              "type": "text",
              txt:'第五天：卡帕多奇亚-安塔利亚。',
            },
            {
              id: 'c41',
              "type": "text",
              txt:'第六天：安塔利亚-D400卡什-费特希耶',
            },
            {
              id: 'c42',
              "type": "text",
              txt:'第七天：费特希耶参加滑翔伞和跳岛游',
            },
            {
              id: 'c43',
              "type": "text",
              txt:'第八天：费特希耶-棉花堡。',
            },
            {
              id: 'c43',
              "type": "text",
              txt:'第九天：棉花堡返回伊斯坦布尔结束难忘的土耳其之旅。',
            },
            {id: 'c16', "type": "img", "url": imgH},
            {
              id: 'c44',
              "type": "text",
              title:'一些精彩分享。',
            },
            {
              id: 'c45',
              "type": "text",
              txt:'第一天：到达土耳其最大的城市伊斯坦布尔，去蓝色清真寺，索菲亚大教堂，博斯普鲁斯海峡感受人文和历史，之后去了独立大街和当地人一样逛一逛品尝当地美食',
            },
            {id: 'c18', "type": "img", "url": imgI},
            {
              id: 'c19',
              "type": "text",
              "txt": "第二天：早晨司机接我们去机场搭乘内陆航班飞往卡帕多奇亚，抵达后有司机接我们去酒店办理入住，之后我们自由在格雷梅镇上探店，格雷梅的网红毯子店的太出片了。"
            },
            {id: 'c20', "type": "img", "url": imgJ},
            {
              id: 'c21',
              "type": "text",
              "txt": "第三天：早晨司机接我们体验热气球，热气球大概3个小时，结束后有香槟早餐，吃完早餐我们回酒店睡了个回笼觉，因为坐热气球真的起的好早啊，下午凉快点了参加了ATV和老爷车日落版太好玩了！"
            },
            {id: 'c22', "type": "img", "url": imgK},
            {
              id: 'c23',
              "type": "text",
              "txt": "第四天：卡帕多奇亚一日游，精灵烟囱，石林教堂 露天博物馆 、黑暗教堂是早期遺留下來的基督教建築群。西元4世紀时期，基督教徒們被罗马帝国、阿拉伯等迫害，只好逃到巨环绕的卡帕多奇亞躲避，因為土耳其中部火山地形遍布，使得卡帕多奇亞地質大多都为质地比较软的的軟岩，基督徒們得以挖掘出无数洞窟穴居，洞窟中的教堂更是多达400座以上。洞穴內部有许多圣经为主的壁画，但西元7世紀时伊斯兰教禁止偶像崇拜，所以壁画上的人物双眼脸孔許多都遭到破坏。"
            },
            {id: 'c24', "type": "img", "url": imgR},
            {
              id: 'c25',
              "type": "text",
              "txt": "第五天：卡帕多奇亚-安塔利亚，早晨司机来酒店接我们本来这一段是要搭飞机的但是由于航班调整没有直飞所以选择驱车前往，建议其她朋友们遇上这种情况可以先去孔亚然后到棉花堡反着走，Selena给我建议了都怪我没听。"
            },
            {id: 'c26', "type": "img", "url": imgL},
            {
              id: 'c27',
              "type": "text",
              "txt": "第六天：司机接我们途径D400和卡什前往费特希耶，D400真的太美了，卡什小镇停下来吃饭休息也好惬意，不想走根本不想走。"
            },
            {id: 'c28', "type": "img", "url": imgM},
            {
              id: 'c29',
              "type": "text",
              "txt": "第七天：早餐后司机接我们去体验滑翔伞，空中俯瞰地中海真的就像一颗蓝色的珍珠太美了，结束后我们又参加了跳岛游，乘坐的是海盗船，船上的外国友人太open了~有点放不开"
            },
            {id: 'c30', "type": "img", "url": imgN },
            {
              id: 'c31',
              "type": "text",
              "txt": "第八天： 费特希耶驱车前往棉花堡打卡，棉花堡景区不大，但是景色也超美，非常适合拍摄美照。"
            },
            {id: 'c30', "type": "img", "url": imgO},
            {
              id: 'c33',
              "type": "text",
              "txt": "第九天：早餐后返回伊斯坦布尔，在伊斯坦布尔逛一逛司机根据我们航班时间提前两个小时送我们抵达机场结束了难以忘记的土耳其之旅，下次还想来"
            },
            {id: 'c34', "type": "img", "url": imgP},
            {
              id: 'c35',
              "type": "text",
              "txt": "此次土耳其之行，非常感谢Selena，为我们提供了很大的帮助以及细心热情的服务，让全程旅行不用操太多的心，保证了我们度假圆满结束，在贴一次Selena的微信，有需要的朋友，请加她："
            },
            {
              id: 'c37',
              "type": "wechat"
            },
            {id: 'c36', "type": "img", "url": imgQ},
          ],
          replay: [ // 精彩回复的评论列表
            {
              id: 'r1',
              avatar: '',
              username: '新加坡-林小惠',
              type: '提问',
              text:' 感谢您的分享，这篇文章太适合我了，感谢感谢！因为疫情几年，所以就想着暑假带孩子去土耳其玩，我在网上找了很多攻略，不知道从哪里下手。在你这联系了Selena,，经过三天的沟通，基本都已经确定好了，机票也已经出了坐等着时间，来一场说走就走的浪漫之旅。'
            },
            {
              id: 'r12',
              avatar: '',
              username: '新加坡-張翔',
              type: '回复',
              aitName:'@新加坡-林小惠',
              text:' 客气了....分享使大家快乐'
            },
            {
              id: 'r13',
              avatar: '',
              username: '新加坡-林小惠',
              type: '回复',
              aitName:'@新加坡-張翔',
              text: '说的对，等我回来，分享下我的整个行程与经历。',
            },
            {
              id: 'r14',
              avatar: '',
              username: '加拿大-彭彭',
              type: '评论',
              text:'去土耳其的想法以前只存在于自己的计划中，这次在Selena的协助下，终于在今年的5月份，完成了我土耳其浪漫热气球的圆梦之旅。我们一行4人背起行囊，前往土耳其，全程10天时间，既体验了也看到了漫天的热气球，又打卡了各种网红店，还欣赏到美丽的土耳其历史文化，更有地道的土耳其美食，烤肉，卷饼，酸奶，沙拉，土耳其咖啡，冰淇淋，牛排等等土耳其美食真的太多了，费特希耶真的好美，喜欢地中海风情，出海游超好玩很刺激!从土耳其回来，太多的精彩瞬间在我的脑海中萦绕，土耳其的迷人风情使人难以忘怀。在走过了五大洲三大洋之后，我依然觉得，就旅游体验而言，仍以土耳其的旅行为最棒。',
              image:imgS
            },
            {
              id: 'r15',
              avatar: '',
              username: '美国-刘雅',
              type: '评论',
              text: '谢谢你们有用的推荐，参加的团已经回家了，品质过硬，服务到位! '
            },
            {
              id: 'r16',
              avatar: '',
              username: 'UK-Kenneth',
              type: '评论',
              text:'土耳其之行Selena安排的非常好。我们采纳了Selena的建议：小车团自由行，一家人单独一台车，全程私密性强，疫情期间还非常安全。,我们一家人，配一台小车，全程有一个司机带着走，省掉了不少麻烦，适合出门想省心的朋友。这次陪伴父母、孩子出来旅游，非常感谢Selena给我们全家人提供的优质服务。',
              image:imgT
            },
            {
              id: 'r17',
              avatar: '',
              username: '加拿大-李洁',
              type: '精彩回复',
              text:'这是一次令人难忘的土耳其之行，看到了许多不一样的人文风情，也看到了卡帕多奇亚的漫天飞舞的热气球，甚至还体验到了土耳其当地特色的洗浴文化 \n 土耳其地处欧亚大陆历史文化遗存非常多，美食也特别棒，费特希耶的蓝海，卡帕多奇亚的浪漫，伊斯坦布尔的丰富文化，阿拉恰特的爱琴海风光，和爱的人在黄昏的码头散散步，坐在遗址的台阶喝点饮料看着夕阳西下真的太惬意和浪漫了 \n 所有的这一切，都让我看到了一个真实的精彩纷呈的土耳其，看到了不同地域的风情人文，看到了基督教徒的力量，激发出深深的思考，这是一片神奇的土地，是一生一定要去一次的地方',
              image:imgU
            },
            {
              id: 'r18',
              avatar: '',
              username: '美国-阿飞',
              type: '',
              text:'可以多分享一些土耳其旅游攻略吗?计划近段过去度假。'
            },
            {
              id: 'r19',
              avatar: '',
              username: '加拿大-李洁',
              type: '',
              wechat:true,
              text:'详细的土耳其旅游攻略，我建议还是联系Selena，我也是找人家安排的，毕竟人家专业，能给到你详细的旅游规划和报价，这样来的比较直接一些。Selena的微信:'
            },
            {
              id: 'r20',
              avatar: '',
              username: '英国-冯娟',
              type: '',
              text:'这次土耳其之行，蓝色清真寺给我留下了美好的回忆，壁画真的是太美了。',
              image:imgV
            },
            {
              id: 'r21',
              avatar: '',
              username: '成都-张欣羽',
              type: '',
              text:'无法用言语形容的土耳其旅行，如果热爱旅行，热爱生活，对世界充满好奇，一定要去一次土耳其。身临斯布鲁斯海峡，脚踏亚欧大陆交汇处，亲眼见证这个伟大的国度，很幸运结识Selena和我们的司机Leo。在一望无际的博斯普鲁斯海峡，乘坐游艇观光，然后享用一顿正宗的土耳其美食，在卡帕多奇亚近距离体验和观看热气球升空，探秘基督教徒为躲避迫害留下的遗址.......太多难忘又令人回味的经历，非常非常推荐!',
              image:imgW
            },
            {
              id: 'r22',
              avatar: '',
              username: '马来西亚-王军',
              type: '精彩回复',
              wechat:true,
              text:'在土耳其每天伴着初升的太阳，在费特希耶享受海风带来的浪漫和新鲜感。这里随时都给人一种丰富和满足的感觉;这里是亚欧大陆的交汇处，不同文化和美食的碰撞让本就充满故事的土耳其更加精彩绝伦，我是带着爸妈去的，跟Selena报的自由行团。因考虑到老人的身体，不敢把行程填太满和太赶，所以这次报的这个团行程比较慢，全程都比较轻松，不仅享受到了土耳其的地道美食也看到了想看的风景。线路设计非常合理，爸妈一点感觉不到累，很符合中老年人出行，吃住方面都非常好。找个土耳其当地的负责人为你设计路线，真的是很接地气。Selena的微信:',
              image:imgX
            },
            {
              id: 'r23',
              avatar: '',
              username: '香港-刘美玲',
              type: '',
              text:'刚刚加上Selena的联系方式，开启我们的土耳其旅行......',
            },
            {
              id: 'r24',
              avatar: '',
              username: '新加坡-张志峰',
              type: '',
              text:'这次我们参加了土耳其之旅，我们老两口年纪比较大，也比较笨，在整个行程中，她每天都会主动关心我们，问候我们有没有需要帮忙的地方。对我们老年游客照顾有加，使我们身在异乡却感受到了感到很温暖，真可谓，不是亲人胜似亲人!'
            }
          ]
        }
      ]
    }
  };

  getMenuIcon (item) {
    const { title } = item
    switch (title) {
      case '首页':
        return (<BankOutlined style={{ fontSize: '20px', color: '#81ccf3', marginLeft: '20px', marginTop: '-10px' }} />)
      case '技术支持':
        return (<InfoCircleOutlined style={{ fontSize: '20px', color: '#81ccf3', marginLeft: '20px', marginTop: '-10px' }} />)
      default:
        break;
    }
  }

  onSearch (value) {
    console.log('searchVal: ', value)
  }

  setQuillCnt (value) {
    console.log('quillVal: ', value)
  }

  onPublish () {
    message.success('感谢评论，稍后将展示！');
  }

  render() {
    const {
      quillCnt,
      blog
    } = this.state
    return (
      <Layout>
        <div className="page">
          {/* web 头部 */}
          <MediaQuery query="(min-device-width: 1200px)">
            <Header style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
              backgroundColor: '#00aa6c',
              height: '73px',
              boxShadow: '0 7px 10px -7px #333'
            }}>
              {/*<img style={{ width: '180px', height: '60px' }} src={newLogo} alt='' />*/}

              <div className='navbar'>
                <span className='menu'>首页</span>
                <span className='menu'>土耳其旅游攻略</span>
                <span className='menu'>土耳其旅游包车</span>
                <span className='menu'>土耳其自由行路线</span>
              </div>

              <Search
                placeholder="请输入您要搜索的内容"
                onSearch={(value) => this.onSearch(value)}
                style={{
                  width: 200
                }}
              />
            </Header>

            <div className='top'>
              <img style={{ width: '100%' }} src={bannerWeb} alt='' />
              <div className='usercard'>
                {/* 用户信息 */}
                <div className='userInfo'>
                  <div className='avatar'>
                    <img className='avatar' src={userAvatar} alt='' />
                  </div>
                  <p className='username'>加拿大-刘君 | 攻略求助</p>
                </div>

                {/* 消息 */}
                <div className='msgleft'>
                  <p className='msg'>计划去土耳其旅行坐热气球，想知道那边安全吗?现在入境政策有哪些要求?求详细的自由行攻略和省钱方案？</p>
                  <div className='introRow'>
                    <FontAwesomeIcon icon={faCarAlt} style={{ fontSize: '20px', color: '#666666', marginLeft: '10px' }} />
                    <p style={{ fontSize: '20px', color: '#666', marginLeft: '10px' }}>土耳其自由行</p>
                    <FontAwesomeIcon icon={faUsers} style={{ fontSize: '20px', color: '#666666', marginLeft: '40px' }} />
                    <p style={{ fontSize: '20px', color: '#666', marginLeft: '10px' }}>土耳其动物大迁徙</p>
                  </div>
                  <div className='tagRow'>
                    <div className='tag1'>
                      <span>酒店攻略</span>
                    </div>
                    <div className='tag2'>
                      <span>路线攻略</span>
                    </div>
                    <div className='tag1'>
                      <span>省钱攻略</span>
                    </div>
                    <div className='tag2'>
                      <span>包车攻略</span>
                    </div>
                  </div>
                  <p className='count'>求助时间：23天前&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;阅读量：9999+</p>
                </div>
              </div>
            </div>
          </MediaQuery>
          
          {/* mobile 头部 */}
          <MediaQuery query="(max-device-width: 1200px)">
            <Header style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 0,
              backgroundColor: '#00aa6c',
              height: '40px',
              boxShadow: '0 7px 10px -7px #333'
            }}>
              <div className='navbar-icon'>
                  <p>首页</p>
                  <p>景点</p>
                  <p style={{color:'#333'}}>游记</p>
                  <p>攻略</p>
              </div>
            </Header>

            <div className='m-top'>
              <img style={{ width: '100%'}} src={bannerM} alt='' />
              <div className='blogger-card'>
                <div className='bc-user'>
                  <div className='userinfo'>
                    <span className='user-tag'>【已完结】计划去土耳其旅行坐热气球，想知道那边安全吗?现在入境政策有哪些要求?求详细的自由行攻略和省钱方案？</span>
                  </div>
                </div>
              </div>
            </div>
          </MediaQuery>

          <MediaQuery query="(min-device-width: 1200px)">
            <div style={{ width: '100%', height: '30px', backgroundColor: 'rgba(0, 0, 0, 0)'}} />
          </MediaQuery>

          {/* 精彩回复 */}
          <div className='content'>
            <MediaQuery query="(max-device-width: 1200px)">
              <div className='link-bar'>
                <img className='link-icon' style={{ marginRight: '50px'}} src={linkIcon} alt='' />
                <img className='link-icon' src={linkIcon} alt='' />
              </div>
            </MediaQuery>

            <div className='main'>
              <div className='cnt-left'>
                <MediaQuery query="(max-device-width: 1200px)">
                  <div className='m-blog-card'>
                    <div className='blog-count'>
                      <span>108条回复
                        <span style={{color:'#1296db',fontSize:'12px'}}>&nbsp;&nbsp;酒店攻略|路线攻略|省钱攻略|包车攻略</span>
                      </span>
                    </div>
                  </div>
                </MediaQuery>
                {
                  blog && blog.goodReplayList
                    && blog.goodReplayList.map(goodItem => (
                      <div style={{ width: '100%', padding: '0px 15px' }} key={goodItem.id}>
                        <div className="user-bar">
                          <div className="msg-avatar">
                            <img className='msg-avatar' src={userAvatar} alt='' />
                          </div>
                          <div style={{display:"flex",flexDirection:'column',marginLeft:'12px'}}>
                            <span className="name">{goodItem.username || ''}</span>
                            <span className="level">Level.6</span>
                          </div>
                          <span className="replay">{goodItem.type || ''}</span>
                        </div>
                        {/* 内容 */}
                        <div className='msg-cnt'>
                          {
                              goodItem.content && goodItem.content.map(contentItem => (
                                  <div style={{width: '100%',fontSize:'16px',lineHeight:'24px'}} id={contentItem.id}>
                                    {
                                      contentItem.title?(
                                          <span style={{color:'red',fontWeight:'600'}}>{contentItem.title}<br/></span>
                                      ):null
                                    }
                                    {
                                      contentItem.url && contentItem.type === 'img'?(
                                          <div>
                                            <img style={{width:'100%'}} src={contentItem.url} alt=''/>
                                          </div>
                                      ):null
                                    }
                                    {
                                      contentItem.txt && contentItem.type === 'text'?(
                                          <span style={{color:'#333333',fontWeight:'400'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{contentItem.txt}
                                            {
                                              contentItem.wechat ?(
                                                  <span style={{color:'red',fontWeight:'600'}}>Ylu222221  <span style={{color:'#33D2FF'}}>[长按即可复制，转到微信加好友]</span></span>
                                              ):null
                                            }
                                          </span>
                                      ):null
                                    }
                                  </div>
                              ))
                          }
                        </div>

                        {/* 消息底部操作栏 */}
                        <div className="option-bar">
                          <FontAwesomeIcon icon={faUser} style={{ fontSize: '12px', color: '#999999', marginLeft: '10px', marginRight: '10px' }} />
                          <span className='option-text'>6条评论&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <FontAwesomeIcon icon={faStar} style={{ fontSize: '12px', color: '#999999', marginRight: '10px' }} />
                          <span className='option-text'>收藏&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;发布于：19天前</span>
                        </div>

                        {/* 评论 */}
                        {
                          goodItem.replay && goodItem.replay.map(item => (
                            <div className='comment-card' key={item.id}>
                              <div className='arrow'></div>
                              <div className='comment-item'>
                                <div className="cmnt-bar">
                                  <div className="cmnt-avatar">
                                    <img className='cmnt-avatar' src={userAvatar} alt='' />
                                  </div>
                                  <h2 className="cmnt-name">{item.username || ''}</h2>
                                  <p className="cmnt-level">{item.type || ''}</p>
                                </div>
                                <span > {item.aitName?(<span style={{color:'#00a2ff',fontWeight:'600'}}>{item.aitName}</span>):null}  {item.text || ''}
                                  {item.wechat?(<span style={{color:'red',fontWeight:'600'}}>Ylu222221  <span style={{color:'#33D2FF'}}>[长按即可复制，转到微信加好友]</span></span>):null}
                                </span>
                                {item.image?(
                                    <img style={{width:'100%'}} src={item.image}/>
                                ):null}
                              </div>
                            </div>
                          ))
                        }
                      </div>
                  ))
                }

                <div className='pub-area'>
                  <TextArea
                    className='textarea'
                    maxLength={100}
                    onChange={() => {}}
                  />
                  <Button className='publish' onClick={() => this.onPublish()}>发表</Button>
                </div>

                <div className='copyright'>
                  Copyright&nbsp;&nbsp;©2023
                  <a style={{ color: '#333' }} href="/">&nbsp;&nbsp;ba-jie.cn</a>
                  <a style={{ color: '#333' }} href="/xprivacyx.html">&nbsp;&nbsp;隐私声明</a>
                  <a style={{ color: '#333' }} href="xcontactx.html">&nbsp;&nbsp;联系我们</a>
                </div>
              </div>

              <MediaQuery query="(min-device-width: 1200px)">
                <div className='cnt-right'>
                  <img className='sidebar-img' src={sidebarImg} alt='' />
                  <p className='side-title'>土耳其旅游-定制师推荐</p>
                  <img className='cs-code' src={csCodeImg} alt='' />
                  <div className="rfbtn">
                    微信号：<span className="weixin">AfricaTrip</span>[长按复制]
                  </div>
                </div>
              </MediaQuery>
            </div>

            {/* 返回顶部 */}
            <FloatButton.BackTop visibilityHeight={20} />
          </div>
        </div>
      </Layout>
    );
  }
}
export default App;