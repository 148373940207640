import React, {Component, useState} from 'react';
import {
    Layout,
    Typography,
    Carousel,
    FloatButton,
    Col,
    Row,
    Image
} from 'antd';
import {
    AppstoreOutlined,
    ContainerOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    BankOutlined,
    MenuOutlined,
    InfoCircleOutlined,
    RightOutlined,
} from '@ant-design/icons';
import styles from './index.css'
import HeaderIndex from '../../Component/header/index.js'
import MediaQuery, {useMediaQuery} from 'react-responsive'
import BannerImg from '../../assets/banner.png'

const {
    Content,
} = Layout;
const {Paragraph} = Typography;
class App extends Component {
    state = {
        collapsed: false,
        menuLeft: '-300px',
        BannerArr: [{'index': 0}, {'index': 1}, {'index': 2}],
        cols: [],
        isMobile: true
    };

    componentDidMount() {
    }

    // isMobile = useMediaQuery({ maxWidth: 1280 })
    componentWillUnmount() {
        this.initData()
    }

    initData() {
        const {cols, isMobile} = this.state

        const colsArr = [];
        for (let i = 0; i < 3; i++) {
            colsArr.push(
                <Col span={isMobile?0:10} style={{display: "flex", flexDirection: 'column',alignItems:'center'}}>
                    <Image
                        width={600}
                        preview={false}
                        src={BannerImg}
                    />
                    <span>{isMobile ? '222' : '111'}</span>
                </Col>
            )
        }
        this.setState({
            cols: colsArr
        })
    }

    renderCol() {
        const {cols} = this.state

        return (
            <Row style={{backgroundColor: 'white'}} align='center' gutter={[20, 20]}>
                {cols}
            </Row>
        )

    }

    render() {
        const {BannerArr} = this.state
        return (
            <Layout className="layout">
                {/*<MediaQuery minWidth={1824}>*/}
                    <HeaderIndex/>
                {/*</MediaQuery>*/}
                <Content>
                    {/* mobile */}
                    <MediaQuery maxWidth={1280}>
                        <Carousel>
                            <view className='banner'>
                            </view>
                            <view className='banner'>
                            </view>
                            <view className='banner'>
                            </view>
                            <view className='banner'>
                            </view>
                        </Carousel>
                    </MediaQuery>
                    {/* Pc Web*/}
                    <MediaQuery minWidth={1281}>
                        <Carousel>
                            <view className='banner-web'>
                            </view>
                            <view className='banner-web'>
                            </view>
                            <view className='banner-web'>
                            </view>
                            <view className='banner-web'>
                            </view>
                        </Carousel>
                    </MediaQuery>
                    <Row style={{backgroundColor: 'white'}} align='center'>
                        <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography.Title>
                                关于我们
                            </Typography.Title>
                        </Col>
                    </Row>
                    {this.renderCol()}
                    <FloatButton.BackTop visibilityHeight={400}/>
                </Content>
            </Layout>
        );
    }
}

export default App;