import React, {Component} from 'react'

import styles from './index.css'
import logoImg from '../../assets/logo.png'
import logoNameImg from '../../assets/logo-name.png'
import {Layout, Menu, Image} from "antd";
import MediaQuery, {useMediaQuery} from 'react-responsive'
const {
    Header
} = Layout;
export default class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabArr: [
                {
                    key: 1,
                    label: '首页',
                },
                {
                    key: 2,
                    label: '私人订制',
                },
                {
                    key: 3,
                    label: '关于我们',
                }]
        }
    }


    render() {
        const {tabArr} = this.state
        return (
            <Header className='header'>
                <view style={{display: "flex", alignItems: 'center'}}>

                    <MediaQuery maxWidth={1280}>
                        <Image
                            width={50}
                            preview={false}
                            src={logoImg}
                            style={{backgroundColor:'red'}}
                        />
                        <Image
                            width={80}
                            preview={false}
                            src={logoNameImg}
                        />

                    </MediaQuery>

                    <MediaQuery minWidth={1281}>
                        <Image
                            className='logoWeb'
                            width={70}
                            preview={false}
                            src={logoImg}
                        />
                        <Image
                            width={100}
                            preview={false}
                            src={logoNameImg}
                        />
                        <Menu
                            theme="light"
                            mode="horizontal"
                            inlineIndent='0'
                            defaultSelectedKeys={['1']}
                            items={tabArr}
                            style={{
                                fontSize: '24px',
                                border: 0,
                                borderBottomWidth: '0px',
                                backgroundColor: 'white',
                            }}
                        />
                    </MediaQuery>

                </view>

            </Header>
        )
    }
}
