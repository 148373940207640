import React, {Component, useState} from 'react';
import {
    Breadcrumb,
    Layout,
    Menu,
    theme,
    Typography
} from 'antd';

const {

} = Layout;
const {Paragraph} = Typography;


class Index extends Component {
    state = {

    };

    onMenu() {
        this.setState({menuLeft: '0px'})
    }

    render() {
        return(
            <div>
                详情页
            </div>
        )
    }
}

export default Index;