import React, {Component, useState} from 'react';
import {
    Layout,
    Typography,
    Col,
    Row,
} from 'antd';
import MediaQuery from 'react-responsive'
import styles from './index.css'
import Bannerimage from '../../assets/list/banner.jpg'

const {Title} = Typography

class Index extends Component {
    state = {};

    onMenu() {
        this.setState({menuLeft: '0px'})
    }

    render() {
        return (
            <Layout>
                <div>
                    <MediaQuery query="(min-device-width: 1200px)" style={{backgroundColor:'white'}}>
                        <div className='banner'>
                            <span className='baner-title'>KENYA</span>
                            <span className='baner-sub'>START A SIMPIE JOURNEY</span>
                        </div>
                        <div className='desc-v'>
                            <Typography.Title level={3}>肯尼亚</Typography.Title>
                            <Typography.Title level={3}>人少景多美如画的动物世界</Typography.Title>
                        </div>
                        <>
                            <Row style={{width:'97%'}} justify="center" >
                                <Col className='label' xs={{ span: 3, offset: 3 }} lg={{ span: 4, offset: 2 }}>
                                    跟团游
                                </Col>
                                <Col className='label' xs={{ span: 3, offset: 3 }} lg={{ span: 4, offset: 2 }}>
                                    定制游
                                </Col>
                                <Col className='label' xs={{ span: 3, offset: 3 }} lg={{ span: 4, offset: 2 }}>
                                    自由行
                                </Col>
                            </Row>
                        </>
                    </MediaQuery>
                    <MediaQuery query="(max-device-width: 1200px)" style={{backgroundColor:'white'}}>
                        <div className='banner'>
                            <span className='baner-title'>KENYA</span>
                            <span className='baner-sub'>START A SIMPIE JOURNEY</span>
                        </div>

                        <div className='desc-v-mobile'>
                            <Typography.Title level={3}>肯尼亚</Typography.Title>
                            <Typography.Title level={3}>人少景多美如画的动物世界</Typography.Title>
                        </div>
                        <Row style={{width:'97%'}} justify="center" >
                            <Col className='label-mobile' xs={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                                跟团游
                            </Col>
                            <Col className='label-mobile' xs={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                                定制游
                            </Col>
                            <Col className='label-mobile' xs={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                                自由行
                            </Col>
                        </Row>

                        {/*列表页*/}
                    </MediaQuery>
                </div>
            </Layout>
        )
    }
}

export default Index;